import React, { Component } from "react";
import axios from "axios";

import RankingsOptions from "./RankingsOptions";
import RankingsTable from "./RankingsTable";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: 2021,
            week: 4,
            teams: [],
            rankings: [],
            games: [],
            seasonWeeks: [],
            rankingYears: [],
            rankingWeeks: [],
            data: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async (scoring, teams) => {
        // await axios
        //     .get("/games?year="+this.state.year)
        //     .then((res) => this.setState({games: res.data}))
        //     .then(() => this.setSeasonWeeks(this.state.year))
        //     .catch((err) => console.log(err));

        await axios
            .get("/teams/?year="+this.state.year)
            .then((res) => this.setState({teams: res.data}))
            .catch((err) => console.log(err));

        await axios
            .get("/rankings/")
            .then((res) => this.setState({rankings: res.data}))
            .then(() => this.setRankingWeeks(this.state.year))
            .then(() => this.setRankingYears())
            .catch((err) => console.log(err));

        await axios
            .get("/teams/")
            .then((res) => this.setState({teams: res.data}))
            .catch((err) => console.log(err));

        // await axios
        //     .get("/rankings?year="+this.state.year)
        //     .then((res) => this.setState({rankings: res.data}))
        //     .then(() => this.setRankingWeeks(this.state.year))
        //     .catch((err) => console.log(err));

        // await axios
        //     .get("/games")
        //     .then((res) => this.setState({games: res.data}))
        //     .catch((err) => console.log(err));
    };

    setYear = (year) => {
        // this.setSeasonWeeks(year);
        this.setRankingWeeks(year);
        this.setState({year: year});
    };

    setWeek = (week) => {
        this.setState({week: week});
    };

    setSeasonWeeks = (year) => {
        let regularSeasonWeeks = new Set();
        let postseasonFound = false;
        for (let game of this.state.games) {
            if (game.year === year) {
                if (game.data.season_type === 'regular') {
                    regularSeasonWeeks.add(parseInt(game.week));
                } else {
                    postseasonFound = true;
                }
            }
        }

        let weeks = Array.from(regularSeasonWeeks);
        weeks.sort(function(a, b) {
            return a - b;
        });
        if (postseasonFound) {
            weeks.push(101);
        }

        this.setState({seasonWeeks: weeks});
    };

    setRankingYears = () => {
        let years = new Set();
        for (let ranking of this.state.rankings) {
            years.add(parseInt(ranking.year));
        }

        this.setState({rankingYears: years});
    };

    setRankingWeeks = (year) => {
        let regularSeasonWeeks = new Set();
        for (let ranking of this.state.rankings) {
            if (ranking.year === year) {
                regularSeasonWeeks.add(parseInt(ranking.week));
            }
        }

        let weeks = Array.from(regularSeasonWeeks);
        weeks.sort(function(a, b) {
            return a - b;
        });

        let weekDropdown = document.getElementById("week-select");
        weekDropdown.value = weeks[weeks.length-1].toString();

        this.setState({rankingWeeks: weeks, week: weeks[weeks.length-1]});
    };


    render() {
        let mainDiv = null;
        if ((this.state.teams.length > 0) && (this.state.rankings.length > 0)) {

            let teams = [];
            for (let team of this.state.teams) {
                if (team.year === this.state.year) {
                    teams.push(team);
                }
            }

            if (teams.length === 0) {
                return null;
            }

            let ranking = "";
            let prevRanking = "";
            let positions = "";
            let prevPositions = "";
            let ratings = "";
            for (let rankings of this.state.rankings) {
                if (rankings.year === this.state.year) {
                    if (rankings.week === this.state.week) {
                        ranking = rankings.order;
                        positions = rankings.positions;
                        ratings = rankings.ratings;
                    } else if (this.state.rankingWeeks.length > 1) {
                        if ((rankings.week === this.state.week - 1) ||
                            ((this.state.week === 101) && (rankings.week === this.state.rankingWeeks[this.state.rankingWeeks.length - 2]))) {
                            prevRanking = rankings.order;
                            prevPositions = rankings.positions;
                        }
                    }
                }
            }
            if (ranking === "") {
                return null;
            }

            let games = [];
            if (this.state.rankingWeeks.length > 1) {
                for (let game of this.state.games) {
                    if (game.year === this.state.year) {
                        if (game.week === this.state.week) {
                            games.push(game);
                        }
                    }
                }
            }

            mainDiv = (
                <div className={"main"}>
                    <div id={"main-content"}>
                        <RankingsOptions
                            year={this.state.year}
                            week={this.state.week}
                            rankingYears={this.state.rankingYears}
                            rankingWeeks={this.state.rankingWeeks}
                            rankings={this.state.rankings}
                            setYear={this.setYear}
                            setWeek={this.setWeek}
                        />
                        <RankingsTable
                            teams={teams}
                            ranking={ranking}
                            prevRanking={prevRanking}
                            positions={positions}
                            prevPositions={prevPositions}
                            ratings={ratings}
                            games={games}
                            year={this.state.year}
                            week={this.state.week}
                        />
                    </div>
                </div>
            );
        }

        return mainDiv;
    }
}

export default Main;
