import React, { Component } from "react";
import Table from 'react-bootstrap/Table';

import RankingRow from "./RankingRow";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function RankingsTable(props) {
    let rankingRows = [];
    let rankingList = props.ranking.split(",");
    let positionsList = props.positions.split(",");

    let prevRankingList = props.prevRanking.split(",");
    let prevPositionsList = props.prevPositions.split(",");

    let ratings = props.ratings.split(",");

    for (let ranking in rankingList) {
        let team = null;
        for (let candidate_team of props.teams) {
            if (candidate_team.team_id.toString() === rankingList[ranking]) {
                team = candidate_team;
                break;
            }
        }

        if (team == null) {
            continue;
        }

        let change = null;
        for (let prevRanking in prevRankingList) {
            if (team.team_id.toString() === prevRankingList[prevRanking]) {
                change = parseInt(prevPositionsList[prevRanking])-parseInt(positionsList[ranking]);
            }
        }

        let prevResult = null;
        let prevOpponent = null;
        let prevOpponentPosition = null;
        for (let year_game of props.games) {
            let prevOpponentId = null;
            let gameFound = false;
            if (year_game.data.home_id === team.team_id) {
                gameFound = true;

                prevOpponentId = year_game.data.away_id;
                prevResult = (year_game.data.home_points > year_game.data.away_points);
            } else if (year_game.data.away_id === team.team_id) {
                gameFound = true;

                prevOpponentId = year_game.data.home_id;
                prevResult = (year_game.data.away_points > year_game.data.home_points);
            }

            if (gameFound) {
                for (let candidate_team of props.teams) {
                    if (candidate_team.team_id === prevOpponentId) {
                        prevOpponent = candidate_team;

                        for (let prevRanking in prevRankingList) {
                            if (prevOpponentId.toString() === prevRankingList[prevRanking]) {
                                prevOpponentPosition = parseInt(prevRanking)+1;
                                break;
                            }
                        }
                        break;
                    }
                }
            }
        }

        rankingRows.push(
            <RankingRow
                team={team}
                position={parseInt(positionsList[ranking])}
                change={change}
                rating={ratings[ranking]}
                prevResult={prevResult}
                prevOpponent={prevOpponent}
                prevOpponentPosition={prevOpponentPosition}
                year={props.year}
                week={props.week}
            />
        );
    }

    return (
        <div id={"rankings"}>
            <Table id={"rankings-table"} >
                <thead>
                    <tr className={"table-header-row"}>
                        <th className={"position-header"}>Rank</th>
                        <th>&nbsp;</th>
                        <th>Team</th>
                        <th>&nbsp;</th>
                        <th className={"rating-header"}>Rating</th>
                        <th className={"record-header"}>Record</th>
                        <th className={"conference-header"}>Conference</th>
                        {/*<th>Last Result</th>*/}
                    </tr>
                </thead>
                <tbody>
                    {rankingRows}
                </tbody>
            </Table>
        </div>
    );
}

export default RankingsTable;
