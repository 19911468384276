import React, { Component } from "react";
import Table from 'react-bootstrap/Table';

import RankingRow from "./RankingRow";

import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';

class RankingsOptions extends Component {
    constructor(props) {
        super(props);
        this.setYear = this.setYear.bind(this);
        this.setWeek = this.setWeek.bind(this);
    }

    setYear = (e) => {
        this.props.setYear(parseInt(e.target.value));
    };

    setWeek = (e) => {
        this.props.setWeek(parseInt(e.target.value));
    };

    render() {

        let reverseRankingWeeks = [...this.props.rankingWeeks];
        reverseRankingWeeks.reverse();

        let weekOptions = [];
        for (let rankingWeek of reverseRankingWeeks) {
            if (rankingWeek === 101) {
                weekOptions.push(<option value={rankingWeek.toString()} selected>Postseason</option>)
            } else {
                weekOptions.push(<option value={rankingWeek.toString()}>Week {rankingWeek}</option>)
            }
        }

        let reverseRankingYears = [...this.props.rankingYears];
        reverseRankingYears.reverse();

        let yearOptions = [];
        for (let rankingYear of reverseRankingYears) {
            yearOptions.push(<option value={rankingYear.toString()}>{rankingYear}</option>)
        }

        return (
            <div id={"rankings-options"}>
                <div className={"select-container"}>
                    <Form.Select
                        id={"year-select"}
                        className={"rankings-options-select"}
                        aria-label="Year selection"
                        onChange={this.setYear}
                        defaultValue={this.props.year.toString()}
                    >
                        {yearOptions}
                        {/*<option value="2021">2021</option>*/}
                        {/*<option value="2020">2020</option>*/}
                        {/*<option value="2019">2019</option>*/}
                        {/*<option value="2018">2018</option>*/}
                        {/*<option value="2017">2017</option>*/}
                        {/*<option value="2016">2016</option>*/}
                        {/*<option value="2015">2015</option>*/}
                        {/*<option value="2014">2014</option>*/}
                    </Form.Select>
                </div>
                <div className={"select-container"}>
                    <Form.Select
                        id={"week-select"}
                        className={"rankings-options-select"}
                        aria-label="Week selection"
                        onChange={this.setWeek}
                        defaultValue={"101"}
                    >
                        {weekOptions}
                    </Form.Select>
                </div>
            </div>
        );
    }
}

export default RankingsOptions;
