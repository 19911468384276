import React, { Component } from "react";

import Button from 'react-bootstrap/Button';
import Collapse from "react-bootstrap/Collapse";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.setOpen = this.setOpen.bind(this);
    }

    setOpen = (open) => {
        this.setState({open: open});
    };

    render() {

        return (
            <header>
                <div id={"app-header"}>
                    <div id={"app-name"}>
                        <h1>Multi Order Win Ratings</h1>
                    </div>
                    <div id={"header-byline-container"}>
                        <div id={"header-byline"}>
                            <h2>
                                MOW Ratings reflect a team's average win percentage against all FBS opponents with
                                adjustments for home, away, and neutral venue results. For opponents that have played
                                each other, a simple head to head win percentage is used for that matchup, otherwise
                                the two teams are evaluated based off how they would perform if playing each other's schedules.
                                MOW Ratings includes the context of a team's conference skill level as an interconference win
                                will benefit all the teams that belong to the winning conference.
                            </h2>
                            <Collapse in={this.state.open}>
                                <div id="example-collapse-text">
                                <h2>
                                    When determining the win percentage of a team against an opponent that a team has not
                                    played, the two teams
                                    are compared by how they would perform if playing all the opponents on each other's schedules.
                                    Team A's performance against all of Team B's opponents is compared with Team B's
                                    performance against those opponents. This is then repeated vice versa with Team B's performance
                                    against all of Team A's opponents being compared with Team A's performance against those opponents.
                                    These two comparisons generate a win percentage between Team A and Team B. The reasoning
                                    is that if Team A wins against all of Team B's opponents while Team B loses all their games,
                                    Team A should be given a high win percentage against Team B even if they did not play each other.
                                </h2>
                                <h2>
                                    However, two teams almost never share the exact same set of opponents. Finding Team A's
                                    performance against all of Team B's opponents requires extra steps if Team A never played
                                    Team C on Team B's schedule. Team A's win percentage against Team C is found by comparing
                                    results against common opponents of Team A and Team C. This win percentage can then be used
                                    in the comparison of Team A and Team B against Team C. If Team A and Team C have no common
                                    opponents, this framework is extended a level deeper to find a common Team D that Team A
                                    and Team C share as opponents, and so on.
                                </h2>
                                <h2>
                                    In some cases early in a season, a win percentage of Team A against an opponent
                                    of Team B cannot be found as there is no path from Team A to the opponent of Team B.
                                    For estimating performance between Team A and Team C where there is no path between
                                    the two teams, the two team's win percentages are used to determine head to head performance.
                                    However, this win percentage is weighted by a team's opponents' win percentages as well.
                                    Team A's win percentage would contribute to half of the weighted average,
                                    their opponents' win percentages would contribute to a quarter,
                                    and those opponent's opponents would contribute an eight, and so on.
                                    The inclusion of opponent win percentage in a team's win percentage provides
                                    context to how good Team A and Team C are in their respective opponent networks.
                                </h2>
                                </div>
                            </Collapse>
                            <h3>
                                <a
                                    onClick={() => this.setOpen(!this.state.open)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={this.state.open}
                                    id={"read-more-btn"}
                                >
                                    Read {this.state.open ? "Less" : "More"}
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
