import React, { Component } from "react";

function RankingsRow(props) {
    let team = props.team.data;

    let color = team.color;
    if (color === "#ffffff") {
        color = team.alt_color;
    }

    let playoffClass = "";
    if (props.position <= 1) {
        playoffClass = "playoff";
    } else if (props.week < 100) {
        if ((props.position <= 2) && (props.year < 2014)) {
            playoffClass = "playoff";
        } else if ((props.position <= 4) && (props.year >= 2014)) {
            playoffClass = "playoff";
        }
    }

    let change = props.change;
    let arrow = null;
    if (props.change >0) {
        arrow = <i className="fas fa-chevron-up" style={{color: "#198754"}}></i>;
    } else if (props.change < 0) {
        arrow = <i className="fas fa-chevron-down" style={{color: "#FF0000"}}></i>;
    } else {
        arrow = <i className="fas fa-minus" style={{color: "#555555"}}></i>;
    }

    if (props.change === 0) {
        change = "";
    } else {
        change = Math.abs(change);
    }

    let prevGame = "";
    let prevGameResult = null;
    if (props.prevOpponentPosition == null) {
        prevGame = "Did Not Play";
    } else {
        prevGameResult = (props.prevResult) ? <span style={{color: "#198754"}}>W</span> : <span style={{color: "#FF0000"}}>L</span>;
        prevGame = " vs. #" + props.prevOpponentPosition.toString() + " " + props.prevOpponent.data.school;
    }

    return (
        <tr>
            <td className={"ranking-row-text position " + playoffClass}>
                {props.position}
            </td>
            <td className={"ranking-row-text logo " + playoffClass}>
                <img src={team.logos[0]} alt={team.abbreviation} width="50" height="50" />
            </td>
            <td className={"ranking-row-text " + playoffClass}>
                <span style={{color: color}}>{team.school}</span> <span className={"mascot"}>{team.mascot}</span>
            </td>
            <td className={"ranking-row-text change " + playoffClass}>
                {arrow} {(props.change != null) ? change : '\xa0\xa0\xa0\xa0'}
            </td>
            <td className={"ranking-row-text rating " + playoffClass}>
                {parseFloat(props.rating).toFixed(2)}
            </td>
            <td className={"ranking-row-text record " + playoffClass}>
                {team.records[props.week].wins}-{team.records[props.week].losses}
            </td>
            <td className={"ranking-row-text conference " + playoffClass}>{team.conference}</td>
            {/*<td className={"ranking-row-text " + playoffClass}>*/}
            {/*    {prevGameResult}{prevGame}*/}
            {/*</td>*/}
        </tr>
    );
}

export default RankingsRow;
